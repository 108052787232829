.profile-container {
    position: relative;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    height: 100%;
    margin-top: 4%;
}

.profile-card {
    position: absolute;
    background-color:#F7F7F7;
    /* width: 744px; */
    width: 400px;
    height: 600px;
    border-radius: 8px;
    z-index: 2;
    box-shadow: 5px 5px 5px #434445;
    /* left: 0; */
}

.profile-card-top {
    width: 400px;
    display: flex;
    flex-direction: column;
    height: 180px;
    position: relative;
}

.profile-card-background {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    filter: brightness(80%);
    width: 400px;
}

.profile-card-background img {
    width: 100%;
    height: 180px;
}

.pic img {
    width: 100%;
    height: 100%;
    /* transition: all 0.2s ease-in-out; */
}

.profile-card-pic {
    border: 1px solid #939598;
    position: absolute;
    width: 120px;
    height: 120px;
    bottom: -25%;
    right: 35%;
    cursor: default;
}

.move-left {
    animation: move-left 2s;
    transform: translateX(-520px);
}

@keyframes move-left {
    from {
        transform: translateX(0);
    }
    to {
        /* left:0; */
        transform: translateX(-520px);
    }
}

.move-right {
    animation: move-right 2s;
    /* transform: translateX(0); */
}

@keyframes move-right {
    from {
        transform: translateX(-520px);
    }
    to {
        /* left:0; */
        transform: translateX(0);
    }
}

.profile-card-bottom {
    display: flex;
    /* align-items: center; */
    margin-left: 10%;
    margin-top: 10%;
    position: relative;
}

.profile-card-bottom ul li {
    padding: 25px;
    font-size: 25px;
    font-weight: 600;
    color: #939598;
    display: flex;
}

.favorite {
    position: absolute;
    /* left: -1200px; */
    transform: translateX(-1200px);
    background-color:#F7F7F7;
    width: 1050px;
    height: 600px;
    /* max-height: 600px; */
    overflow-y: scroll;
    border-radius: 8px;
    /* animation: slide-in 3s;
    animation-fill-mode: forwards; */
    box-shadow: 5px 5px 5px #434445;
    /* transform: translateX(-1050px); */
    /* transition: transform 0.5s; */
    opacity: 0;
    z-index: 1;
}

.slide-in-favorites {
    animation: slide-in 3s;
    animation-fill-mode: forwards;
}

.slide-out-favorites {
    animation: slide-out 3s;
    animation-fill-mode: forwards;
}

@keyframes slide-out {
    0% {
      /* transform: translateX(-1050px); */
      /* transform: translateX(0); */
      transform: translateX(220px);
      opacity: 1;
    }
    100% {
      transform: translateX(-1200px);
      opacity: 0;
    }
}

@keyframes slide-in {
    0% {
      /* transform: translateX(-1050px); */
      /* transform: translateX(0); */
      transform: translateX(-1200px);
      opacity: 0;
    }
    100% {
      transform: translateX(220px);
      opacity: 1;
    }
}


.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.grid-item {
    padding: 20px;
    /* font-size: 30px; */
    text-align: center;
}

.grid-item img {
    height: 120px;
    cursor: pointer;
}

.favorite-header {
    padding: 30px;
    font-size: 35px;
    color: #939598;
    border-bottom: 1px solid #939598;
}

#profile-expand {
    position: absolute;
    transform: rotate(-90deg);
    top: -10px;
    right: 155px;
    color: #4c4e51;
    font-size: 30px;
    padding-right: 10px;
    cursor: pointer;
}

#profile-collapse {
    position: absolute;
    transform: rotate(90deg);
    /* top: -5px; */
    right: 155px;
    color: #4c4e51;
    font-size: 30px;
    padding-right: 10px;
    cursor: pointer;
}

.expand-favorite {
    position: absolute;
    display: flex;
    flex-direction: row;
    right: -48%;
    animation: blink 2s linear infinite;
}

.expand-favorite p {
    color: #939598;
    font-size: 20px;
}


@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.user-info {
    display: flex;
    gap: 10px;
}

.user-info p {
    font-size: 25px;
    color: #4e4f52;
    font-weight: 600;
}

#follow-button {
    display: flex;
    /* justify-content: center; */
    margin: 0 auto;
    margin-top: 25px;
}

#follow-button i {
    padding-right: 7px;
    padding-top: 0.6px;
}

.follow-container {
    position: fixed;
    background-color:#F7F7F7;
    width: 27vw;
    height: 80vh;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    right: -27.2vw;
    top: 13vh;
    box-shadow: -5px 5px 5px #434445;
    z-index: 3;
    transition: right 0.5s ease-in-out;
}

.follow-container:hover {
    right: 0;
}

.follow-tag {
    position: absolute;
    background-color: #F7F7F7;
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(-90deg);
    left: -100px;
    top: 60px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    box-shadow: 0 -5px 5px #434445;
}

.follow-tag p {
    font-weight: 600;
    color: #939598;
}

.follow-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 50px;
    border-bottom: 1px solid #939598;
}

.follow-followers, .follow-following {
    flex-basis: 50%;
    text-align: center;
    border-right: 1px solid #939598;
    cursor: pointer;
    color: #939598;
    font-weight: 800;
}

.add-color {
    background-color: #908c8c;
}

.add-color p{
    color: #F7F7F7;
}

.follow-followers:hover, .follow-following:hover {
    background-color: #d1cbcb;
}

.follow-detail {
    max-height: 72.8vh;
    overflow-y: scroll;
    padding-top: 4%;
    padding-left: 20%;
}

.follow-card-top {
    width: 16.5vw;
    height: 8vh
}

.follow-card-background {
    width: 16.5vw;
}

.follow-card-profile {
    border: 1px solid #939598;
    position: absolute;
    width: 60px;
    height: 60px;
    bottom: -45%;
    right: 35%;
    cursor: pointer;
}

.follow-card {
    height: 16vh;
    width: 16.5vw;
    box-shadow: 5px 5px 5px #434445;
    margin-bottom: 30px;
    background-color: #939598;
    border-radius: 8px;
    cursor: pointer;
}

#follow-card-username {
    font-size: 15px;
    color: #F7F7F7;
    display: flex;
    margin: 0 auto;
}

#follow-card-info {
    padding-top: 40px;
}

.follow-card:hover {
    filter: brightness(70%);
}

.add-color {
    background-color: #b2b4b7;
    color: #939598;
}

.explore-container {
    position: fixed;
    background-color:#F7F7F7;
    width: 27vw;
    height: 58.5vh;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    right: -27.2vw;
    top: 33.3vh;
    box-shadow: -5px 5px 5px #434445;
    z-index: 3;
    transition: right 0.5s ease-in-out;
}

.explore-container:hover {
    right: 0;
}

.explore-detail {
    max-height: 56.55vh;
}

.edit-user-info {
    padding-top: 60px;
    display: flex;
    justify-content: center;
}

.edit-username-button-container {
    position: absolute;
    right: 0;
    padding: 10px;
}

.edit-username-button {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    color: #4e4f52
}

.edit-username-button:hover {
    cursor: pointer;
    background-color: #e2dbdb;
}

.edit-username-modal {
    position: absolute;
    background-color:#F7F7F7;
    width: 350px;
    height: 250px;
    border-radius: 8px;
    top: 32px;
}

.edit-username-header {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    font-size: 19px;
    color: #4e4f52;
}

.edit-username-input {
    display: flex;
    margin: 0 auto;
    margin-top: 30px;
    height: 10px;
    padding: 10px;
    font-size: 16px;
    border-radius: 8px;
    background-color: transparent;
    color: #4e4f52;
}

.edit-username-submit {
    display: flex;
    margin: 0 auto;
    margin-top: 30px;
    height: 34px;
    width: 150px;
    justify-content: center;
    font-size: 14px;
    border-radius: 8px;
    background-color: transparent;
    border: none;
    color: #4e4f52;
}

.edit-username-input:hover {
    cursor: pointer;
}

.edit-username-submit:hover {
    cursor: pointer;
}