.session-form > .errors {
  color: red;
  font-size: 0.68em;
  height: 15px;
  display: flex;
  align-items: flex-start;
  width: 255px;
  margin-bottom: 6px;
  margin-top: 4px;
  font-family: 'Times New Roman', Times, serif;
}

#confirm-error {
  margin-bottom: 5px;
}

.session-modal {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /* box-shadow: 5px 5px 5px #939598; */
  box-shadow: 8px 8px 8px #686b74;
}

.welcome {
  /* float: left; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: 50%;
  background-color: #939598;
  color: #F7F7F7;
  position: relative;
}

.welcome p {
  text-align: center;
  font-size: 18px;
}

.welcome img {
  height: 200px;
  margin-right: 10px;
  /* position: absolute;
  top: 0;
  left: 0; */
}

.session-form {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  margin: 10px;
  /* float: right; */
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

.close-form {
  position: absolute;
  top: 8px;
  right: 7px;
  color:#939598;
  background-color: transparent;
  box-shadow: none;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  height: 27px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-form:hover {
  background-color: #dbdee3;
}

.session-form label {
  margin-bottom: 10px;
  display: flex;
  color: #939598;
  display: flex;
  justify-content: flex-start;
  width: 255px;
}

.session-input {
  width: 250px;
  line-height: 26px;
  outline: none;
  border: none;
  background-color: #F7F7F7;
  border-bottom: 1px solid #939598;
  padding-bottom: 2px;
}

.session-input::placeholder {
  color: #b1b3b6;
}

.session-input:focus {
  box-shadow: 0 1px 0 #939598;
}

.input {
  position: relative;
}

.input i {
  position: absolute;
  right: 5px;
  color: #939598;
  cursor: pointer;
  bottom: 7px;
  font-size: 14px;
}

.submit-form {
  border: 2px solid #939598;
  padding: 8px 15px;
  border-radius: 50px;
  background-color: #F7F7F7;
  font-weight: 550;
  color: #5d6164;
  /* cursor: not-allowed; */
  cursor: pointer;
}

.submit-form:hover {
  color: #939598;
  background-color: #d9d6d6;
}

.allow-submit {
  cursor: pointer;
}

#demo-login{
  border: none;
  position: absolute;
  right: 0;
  bottom: 100px
}

.switch {
  font-size: 12px;
  margin-top: 30px;
  /* position: absolute; */
  /* width: 100%; */
  font-family: 'Times New Roman', Times, serif;
}

.switch button {
  font-size: 12px;
  color:#F7F7F7;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.switch button:hover {
  text-decoration: underline;
}