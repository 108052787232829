body {
    background-color: #F4EEE0;
    font-family: 'Lora', serif;
}

#navbar {
    top: 0;
    display: flex;
    width: 100vw;
    background-color: #F7F7F7;
    box-sizing: border-box;
    align-items: center;
    z-index: 100;
}

#nav-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-height: 9vh;
}

#profile-drop {
    padding-right: 10px;
    position: relative;
    display: block;
    z-index: 1001;
}

.profile-drop-button {
    cursor: pointer;
    padding: 18px;
    margin-top: 10px;
}

#profile-pic-button {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    color: #939598;
}

.pic {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    overflow: hidden;
    cursor: pointer;
}

#session-buttons {
    margin-top: 0;
}

#logo-button {
    margin-left: 20px;
}

#logo-button img {
    height: 63px;
}

#logo-button:hover img:last-child {
    opacity: 1;
    right: 0;
}

.dropdown-items {
    position: absolute;
    background-color: #F4EEE0;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    right: 10px;
    width: 170px;
    font-size: 100%;
    display: block;
    z-index: 1002;
}

.dropdown-items li {
    padding-bottom: 7px;
    padding-top: 7px;
    cursor: pointer;
    padding-left: 15px;
}

.dropdown-items li:last-child {
    border-top: 1px solid #A9907E;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.dropdown-items li:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.dropdown-items li:hover {
    background-color: #A9907E;
    text-decoration: underline;
}

#test {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    font-size: 20px;
    color: #58595c;
}

.about-us, .profile-drop-button {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;
}

.about-us button:first-child, .profile-drop-button button:first-child{
    margin-right: 20px;
    border: none;
}

.about {
    border: 2px solid #939598;
    padding: 13px 18px;
    border-radius: 50px;
    background-color: #F7F7F7;
    font-weight: 550;
    cursor: pointer;
    color: #5d6164;
}

.about:hover {
    color: #939598;
}

.profile-drop-button img {
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

.map-button {
    background-color: #F7F7F7;
    font-size: 24px;
    color: #939598;
    border-radius: 100%;
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: absolute;
    border: none;
    right: 7%;
    top: 1.3%;
}

.map-button p{
    font-size: 14px;
}

.map-button:hover {
    color: #F7F7F7;
    background-color:#939598;
}

.nav-buttons:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: grey; */
    width: 35%;        
}
