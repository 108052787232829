.art-display-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    display: flex;
    max-height: 500px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    background-color: rgb(209, 209, 209);
}

.art-display-container.user {
    width: 95%;
}

.s-image-container {
  background-color: rgb(209, 209, 209);
  height: 500px;
  min-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#fetched-image {
  height: auto;
  max-height: 90%;
  width: auto;
  max-width: 90%;
  pointer-events: none;
}

.art-info {
    font-size: 22px;
    font-family: ui-sans-serif;
    position: relative;
    line-height: 26px;
    background-color: rgb(209, 209, 209);
    max-height: 500px;
    max-width: 500px;
    overflow-y: scroll;
    height: 500px;
    width: 50vh;
    align-items: space-between;
    text-align: center;
}

.art-info h1 {
    padding-bottom: 16px;
    font-weight: 600;
}

.art-info h1:first-child {
    padding-top: 50px;
}

.art-info h2 {
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.art-info h3 {
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
}


.close-display {
    font-size: 28px;
    width: 45px;
    height: 45px;
}

.art-display-buttons {
    flex-direction: column;
}

/* .art-display-buttons > * {
  padding-right: 30px;
} */

.fav-buttons {
    top: 11%;
    right: 0.8%;
    font-size: 28px;
    width: 45px;
    height: 45px;
}

#favorited {
    color: brown;
}

.favorite-button {
    height: 200px;
    width: 100px;
    padding: 5px;
}

#x-button {
    border-style: none;
    padding-left: 10px;
    font-size: -webkit-xxx-large;
    padding-right: 10px;
    border: 2px solid black;
}

.next-button {
    position: absolute;
    top: 3%;
    right: 5.5%;
    display: flex;
    background-color: transparent;
    border: none;
    color: #707275;
    cursor: pointer;
}

.next-button i {
    font-size: 27px;
}

#next-artwork-left {
    animation: click-here-left 1.5s infinite;
}

@keyframes click-here-left {
    0% {
        transform: translateX(-20px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  }