.about-us-wrapper {
    width: 50%;
    margin: 0 auto;
    margin-top: 15%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 5px 5px 5px #434445;
}

.about-us-title {
    font-size: 29px;
    padding: 20px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #939598;
}

.about-us-description {
    text-align: center;
    line-height: 1.4825;
    font-size: 19px;
    padding: 30px 50px;
}