.developer-container {
    background-color: #F7F7F7;
    width: 21.5vw;
    box-shadow: 5px 5px 5px #434445;
    text-align: start;
    border-radius: 8px;
    border: 1px solid #9da0a2;
    margin-bottom: 20px;
}

.developer-top {
    display: flex;
    position: relative;
}

.developer-background {
    width: 100%;
    height: 17vh;
    border-top-right-radius: 8px;
}

.developer-background img {
    width: 100%;
    height: 100%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.developer-profile-pic {
    border: 1px solid #9fa1a3;
    position: absolute;
    width: 110px;
    height: 110px;
    top: 50%;
    left: 50%;
    transform: translate(-50px);
    cursor: default;
}

.developer-profile-pic img {
    width: 100%;
    height: 100%;
}

.contact-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
    height: 90vh;
}

.developer-grid-item {
    padding: 20px;
    padding-top: 100px;
    font-size: 30px;
    text-align: center;
    position: relative;
    animation: fade-in 3s;
    animation-fill-mode: forwards;
    opacity: 1;
    margin-top: -10px;
}

@keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
}

.developer-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.developer-info p {
    font-size: 25px;
    color: #4e4f52;
    font-weight: 600;
}

.developer-info p:last-child {
    line-height: 40px;
    font-size: 17px;
}

.developer-title p{
    font-weight: 100;
    font-size: 26px;
    color: #939598;
}

.developer-name {
    line-height: 2em;
}

.developer-bio {
    background-color: #F7F7F7;
    height: 32vh;
    overflow-y: scroll;
    padding: 0.8vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    max-width: 19.1vw;
    word-wrap: break-word;
    font-size: 16px;
    border-radius: 8px;
    line-height: 30px;
}

.developer-link {
    background-color: #F7F7F7;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    height: 12vh;
    width: 10vw;
    position: absolute;
    right: 5%;
    border: 1px solid #9fa1a3;
    border-radius: 8px;
}

.link-grid-item{
    padding: 10px;
    font-size: 30px;
    text-align: center;
}

.link-grid-item img {
    height: 30px;
}

.developer-projects {
    /* width: 20vw; */
    position: relative;
}

#developer-expand {
    color: #939598;
    display: flex;
    top: -30px;
    left: 50%;
    padding: 5px;
    position: absolute;
    transform: translate(-50%);
    animation: slide-down 1.5s infinite;
    animation-fill-mode: forwards;
}

@keyframes slide-down {
    0% {
      transform: translateX(-50%) translateY(-30%);
      opacity: 0;
    }

    90% {
        opacity: 1;
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
}

.projects-container {
    position: absolute;
    top: 0;
    transform: translateY(0);
    width: 20vw;
    border-radius: 8px;
    background-color: #F7F7F7;
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 5px 5px 5px #434445;
    animation: drop-down 1s;
    animation-fill-mode: forwards;
    /* z-index: 1; */
    opacity: 1;
}

@keyframes drop-down {
    0% {
      transform: translateY(-200%);
      opacity: 0;
    }

    90% {
        opacity: 1;
    }

    100% {
      transform: translateY(0);
    }
}

.projects-container img {
    height: 30px;
}

.projects-container a {
    transition: transform 0.3s ease;
    cursor: pointer;
}

.projects-container a:hover {
    transform: scale(1.6);
}