.our-map {
    position: relative;
    height: 87vh;
    /* max-height: 90vh; */
    /* min-height: 93vh; */
    /* overflow: auto; */
    /* width: 100%; */
    background-color: skyblue;
}

/* .leaflet-popup-content-wrapper {
    scale: calc(1.2);
} */

.filter-info {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 200000;
    display: flex;
    gap: 5px;
    top: 20px;
    font-size: 25px;
}

.MuiSlider-markLabel.makeStyles-markLabel-5 {
    font-size: 1.3vw;
}

/* .next-button {
    position: fixed;
    left: 38.5%;
    top: 81.5%;
    z-index: 1000;
    font-size: 18px;
    padding-left: 50px;
    padding-right: 50px;
    cursor: pointer;
}

#next-artwork-left {
    animation: click-here-left 1.5s infinite;
}

#next-artwork-right {
    animation: click-here-right 1.5s infinite;
}

.MuiSlider-markLabel.makeStyles-markLabel-5 {
    font-size: 1.3vw;
}

@keyframes click-here-left {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes click-here-right {
    0% {
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
} */

.leaflet-popup-content-wrapper {
    border-radius: 8px;
    width: 200px;
    height: 50px;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: rgb(209, 209, 209);
}

.leaflet-popup-content {
    display: flex;
    justify-content: center;
    color: black;
    font-family: ui-sans-serif;
    font-size: 16px;
}

a.leaflet-popup-close-button {
    margin: 1.5px;
}

.leaflet-container a.leaflet-popup-close-button {
    color: #939598;
    font: 18px/22px Tahoma, Verdana, sans-serif;
}

a.leaflet-popup-close-button:hover {
    border-radius: 100%;
    background-color: #dbdee3;
}

.leaflet-container a.leaflet-popup-close-button:hover {
    color: #939598;
}

.no-art {
    background-color: #e8e8e8;
    position: fixed;
    width: 15%;
    left: 2%;
    bottom: 13%;
    z-index: 9999;
    padding: 10px;
    border: 3px ridge red;
    font-family: Arial, Helvetica, sans-serif;
}

.no-art h1 {
    font-size: x-large;
    text-align: center;
}

.fetching-art-message {
    background-color: #e8e8e8;
    position: fixed;
    width: 15%;
    left: 2%;
    bottom: 13%;
    z-index: 9999;
    padding: 10px;
    border: 3px ridge blue;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.fetching-art-message h1 {
    font-size: x-large;
    text-align: center;
    font-weight: bold;
}