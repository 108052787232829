.modal {
    position: fixed;
    top: 30%;
    right: 50%;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    width: 100%;
    height: 100%;
    /* width: 744px; */
}

.modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
    position: absolute;
    background-color:#F7F7F7;
    /* width: 744px; */
    width: 650px;
    height: 415px;
    border-radius: 8px;
    top: 32px;
    z-index: 1100;
}